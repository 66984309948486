import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../../Components/Admin/assets/icons/a1.png";
import colunms from "../../../../Components/Admin/assets/icons/LINES.png";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import top from "../../../../Components/Admin/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faRotate, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../../common/ModalDelete";

import { AddButton, EditButton, DeletButton } from "../../../common/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
// import SettingContent from "../SettingContent/SettingContent";
import { Button } from "react-bootstrap";
// import OffcanvasCon from "../../OffcanvasCon/OffcanvasCon";
import Select from "react-select";
import { Context } from "../../../../utils/context";
library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    IMG_URL,
    isAllow,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [searchStatus, setSearchStatus] = useState("");
  const getDataAll = async () => {
    let url = `/wallet/request-rejected?page=${currentPage}&per_page=${perPage}&search=${search}`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`;
    }
    const response = await getData(url);
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search);

    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1]);

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  return (
    <>
      <div className="Dashboard">
        <Header title={"Request Rejected"} link={"/wallet/request-rejected"} />
        <section className="Advancemain-advancedashboard">
          <div className="row">
            <div className="col-lg-12">
              <section className="Tabels tab-radio tab-radio mt-3">
                <div className="">
                  {/* container */}
                  <div className="row">
                    <div className="d-flex">
                      <div className="add me-3"></div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-4">
                      <div className="  col-lg-4 col-md-12 col-12">
                        <div className="d-flex">
                          <div className="show me-2">
                            <p className="show mt-1">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries">
                            <p className="show mt-1">entries</p>
                          </div>
                        </div>
                      </div>
                      <div className="  col-lg-8  col-md-12 col-12">
                        <div className="d-flex justify-content-end">
                          <div className="sowing me-2">
                            <p className="show mt-2">{`Showing ${Math.min(
                              (currentPage - 1) * perPage + 1
                            )} to ${Math.min(
                              currentPage * perPage,
                              data?.data?.totalEntries
                            )} of ${data?.data?.totalEntries} entries`}</p>
                          </div>

                          <div className="num me-2">
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              placeholder="search by contact no and name"
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                          <div className="Search me-2">
                            <button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </button>
                          </div>

                          <div className="Search-1">
                            <button
                              type="button"
                              onClick={() => {
                                setSearch("");
                                setSearchStatus("");
                                setReset(!reset);
                              }}
                              className="btn btn-reset"
                            >
                              <FontAwesomeIcon icon={faRotate} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {" "}
                            {visible.col1 && (
                              <th className="tax-name">Sr. No.</th>
                            )}{" "}
                            {visible.col0 && <th className="tax-name">User</th>}
                            <th className="tax-name">Contact No</th>
                            {visible.col2 && (
                              <th className="tax-name">IFSC CODE </th>
                            )}
                            {visible.col3 && <th className="tax-name">A/C </th>}
                            {visible.col4 && (
                              <th className="tax-name">Amount</th>
                            )}
                            {visible.col5 && <th className="active">Action</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data?.data?.map((d, index) => {
                            return (
                              <tr className="" key={index}>
                                {" "}
                                {visible.col1 && <td>{++index}.</td>}
                                {visible.col0 && (
                                  <td>{`${d?.user?.f_name} ${d?.user?.l_name}`}</td>
                                )}
                                {visible.col0 && <td>{d?.user?.contact_no}</td>}
                                {visible.col2 && <td>{d?.ifsc_code}</td>}
                                {visible.col3 && <td>{d?.ac_no}</td>}
                                {visible.col4 && <td>{d?.amount}</td>}
                                <td>
                                  <div className="d-flex">
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                    >
                                      Rejected
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Tables;
