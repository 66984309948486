import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
// import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Col, Container, Row, Form, InputGroup } from "react-bootstrap";
library.add(fas);
const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL } = useContext(Context);
  const [errors, setErrors] = useState();
  const id = props.show;
  const [formData, setFormData] = useState({
    remark: "",
    status: 0,
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let errors = {};

    if (!formData.remark) {
      errors.remark = "Remark is required";
    } else if (formData.remark.length > 200) {
      errors.remark = "Remark Must be 200 characters Maximum";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const data = {
          remark: formData?.remark,
          status: 0,
          id: id,
        };
        const response = await postData(`/wallet/update-request`, data);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit User Advertisement</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container fluid>
            <Row>
              {/* Right side - Form */}
              <Col md={12}>
                <Card className="shadow-lg p-3 mb-5 bg-white rounded">
                  <Card.Body>
                    <Card.Title>Requested Rejected</Card.Title>
                    <hr />
                    <Container>
                      <Row>
                        {/* Editing fields */}
                        <Col md={8}>
                          <Row>
                            <Col md={12}>
                              <div className="main-form-section mt-5">
                                <Row className="justify-content-center">
                                  <Form.Label column sm={3}>
                                    Remark
                                  </Form.Label>
                                  <Col sm={6}>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          name="remark"
                                          value={formData.remark}
                                          onChange={handleChange}
                                          maxLength={50}
                                          placeholder="Remark "
                                          id="inputEmail3"
                                          className="input-mandatory"
                                        />
                                      </InputGroup>
                                      {errors?.remark && (
                                        <span style={errorStyle}>
                                          {errors?.remark}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mt-5 pb-3">
                            <div className="d-flex justify-content-center">
                              <Link>
                                <CancelButton
                                  name={"cancel"}
                                  handleClose={props.handleClose}
                                />
                              </Link>
                              <SaveButton
                                name={"save"}
                                handleSubmit={handleSubmit}
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};
export default EditOffCanvance;
